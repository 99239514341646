@import "slick-carousel/slick/slick.css";
// @import "slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import 'react-image-lightbox/style.css';
@import '~bootstrap/scss/bootstrap';
@import "/public/assets/scss/main.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;700&display=swap');

// Apply Roboto to text
body,
p,
a {
    font-family: 'Roboto', sans-serif;
}

// Apply Saira to headings
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Saira', sans-serif;
}