// font url

$font-url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;700&display=swap');


// font family
$font-family: (
    ff: (body: "Roboto",
        heading: "Saira Bold",
        p: "Roboto",
        fontawesome: '"Font Awesome 5 Pro"',
    )
);

// Apply Roboto to text
body {
    font-family: 'Roboto', sans-serif;
}

// Apply Saira to headings
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Saira', sans-serif;
}


// font weight
$font-scale: (
    fw: (normal: normal,
        elight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        sbold: 600,
        bold: 700,
        ebold: 800,
        black: 900,
    )
);

// font size
$font-size: (
    fz: (body: 14px,
        p: 16px,
        h1: 40px,
        h2: 36px,
        h3: 24px,
        h4: 20px,
        h5: 16px,
        h6: 14px,
    )
);